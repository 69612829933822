import React from 'react';
import ReactDOM from 'react-dom';
// import './Header.css'
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import App from './Component/App';
// import '../public/index.html'
// import Header from './Component/Header';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     {/* <App /> */}
//     <Header />
//   </React.StrictMode>
// );

ReactDOM.render(
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>,
  document.getElementById('root')
);

reportWebVitals();

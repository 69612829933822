// import React, { useEffect } from 'react'
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle";
// import "../../"
import { Route, Routes } from 'react-router-dom'
import Home from './Home';
import Contact from './Contact';
import Navbar from "./Navbar";
import Blogs from './Blogs';
import About from './About';
import Ourwork from './Ourwork';
import Gallery from './Gallery';
import Footer from "./Footer";
import '../Header.css'
// import '../../public/index.html'
// import '../../public/index.html';


const App = () => {
    
    return (
        <>
        <head>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <title>FARMBridge.org</title>
        </head>
            <Navbar />
            <Routes>
                <Route exact path='/' Component={Home} />
                <Route exact path='/about' Component={About} />
                <Route exact path='/ourwork' Component={Ourwork} />
                <Route exact path='/contact' Component={Contact} />
                <Route exact path='/blogs' Component={Blogs} />
                <Route exact path='/gallery' Component={Gallery} />
                {/* <Route exact path='/footer' Component={Footer} /> */}
       
                
            </Routes>
            <Footer />
            {/* <Navigate to="/" /> */}
        </>
    )
}

export default App;
import React from 'react';
import '../Gallery.css'
import { Link } from 'react-router-dom';
import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img4 from '../images/img4.jpg'
import img6 from '../images/img6.jpg'
import vision from '../images/vision.jpg'


const Gallery = () =>{
    return(
        <>
        <div class="container-fluid gall" id='sgall'>
                <div class="row">
                    <div className='gall_tit'>
                        <div className='gall_tit1'>
                            <h5>GALLERY</h5>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className=" bg-light gall_im text-center">
                            <img src={img1} alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className="bg-light gall_im">
                            <img src={img2} alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className="gall_im bg-light">
                            <img src={img3} alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className="gall_im bg-light">
                            <img src={img4} alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className="gall_im bg-light">
                            <img src={img6} alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-4 col-lg-4 p-2 gall_img">
                        <div className="gall_im bg-light">
                            <img src={vision} alt="" className='img-fluid'/>
                        </div>
                    </div>
                </div>

                <div className='gall_btn'>
                    <div className='gall_btn1'>
                        <Link to="/" className='gall_btn2'>BACK</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Gallery;
import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";
import '../Contact.css'
import { Link } from 'react-router-dom';

const Contact = () => {
  const form = useRef();

  function refreshPage() {
    window.location.reload(false);
  }
  

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_iiml8g6', 'template_wmcjuzs', form.current, {
        publicKey: 'bqrlrYNlgrA0TbEpT',
      })
      .then(
        () => {
          alert('MASSAGE SENT SUCCESSFULLY!!!');
        },
        (error) => {
          alert('FAILED...PLEASE TRY AGAIN LATER.', error.text);
        },
        form.current.reset()
      );
  }
  return (
    <>
    <div className="container-fluid main" >
      <form className="contact-form" ref={form} onSubmit={sendEmail}>
        <div className='sub row'>
          <div className="form-group col-md-6 d-flex col-sm-12">
            <label htmlFor="name" for="validationServer01" className='form-label col-md-3' >Name</label>
            <input type="text" name='user_name' className="form-control col-md-3" id="name" required />
          </div>
          <div className="form-group col-md-6 d-flex col-sm-12">
            <label htmlFor="exampleInputEmail1" for="validationServer01" className='form-label col-md-3'>Email</label>
            <input type="email" name='user_email' className="form-control col-md-3" id="email" aria-describedby="emailHelp" required />
          </div>
          <div className="form-group col-md-6 d-flex col-sm-12">
            <label htmlFor="message" className='col-md-3 form-label msg'>Message</label>
            <textarea className="form-control col-md-3" type='text' rows="5" id="message" name='message' />
          </div>
        </div>
        <div className="btn d-flex">
          <div className='cnt1_btn btn1 col col-sm-12 col-md-6 col-lg-6 col-xl-3' >
            <div className='cnt1_btn1'>
              <button className="cnt1_btn2 cnt2_btn2" type="submit">SUBMIT</button>
            </div>
          </div>
          <div className='cnt1_btn col col-sm-12 col-md-6 col-lg-6 col-xl-3'>
            <div className='cnt1_btn1'>
              <Link to="/" className='cnt1_btn2'>BACK</Link>
            </div>
          </div>
        </div>
      </form>
    </div>

                                       {/* LOCATION */}

    <div className='container-fluid'>
      
    </div>
    </>
  );
}

export default Contact;



{/* <form class="row g-3">
  <div class="col-md-6">
    <label for="inputEmail4" class="form-label">Email</label>
    <input type="email" class="form-control" id="inputEmail4">
  </div>
  <div class="col-md-6">
    <label for="inputPassword4" class="form-label">Password</label>
    <input type="password" class="form-control" id="inputPassword4">
  </div>
  <div class="col-12">
    <label for="inputAddress" class="form-label">Address</label>
    <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
  </div>
  <div class="col-12">
    <label for="inputAddress2" class="form-label">Address 2</label>
    <input type="text" class="form-control" id="inputAddress2" placeholder="Apartment, studio, or floor">
  </div>
  <div class="col-md-6">
    <label for="inputCity" class="form-label">City</label>
    <input type="text" class="form-control" id="inputCity">
  </div>
  <div class="col-md-4">
    <label for="inputState" class="form-label">State</label>
    <select id="inputState" class="form-select">
      <option selected>Choose...</option>
      <option>...</option>
    </select>
  </div>
  <div class="col-md-2">
    <label for="inputZip" class="form-label">Zip</label>
    <input type="text" class="form-control" id="inputZip">
  </div>
  <div class="col-12">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="gridCheck">
      <label class="form-check-label" for="gridCheck">
        Check me out
      </label>
    </div>
  </div>
  <div class="col-12">
    <button type="submit" class="btn btn-primary">Sign in</button>
  </div>
</form> */}
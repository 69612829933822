import React from "react";
import Logo from "../images/Logo.jpg"
import { Link, NavLink } from "react-router-dom";


const Navbar = () => {
    return (
        <>
            <header className="site-header css_prefix-header-style-two" id="main-header">
                <div className="container">
                    <div className="row">
                        <div className="col-11 mx-auto">
                            <div className="css_prefix-top-header">
                                <div className="row align-items-center">
                                    <div className="col text-start">
                                        <ul className="list-inline mb-0 contact-info d-flex">
                                            <li className="list-inline-item">
                                                <div className="css_prefix-address d-flex align-items-center">
                                                    <div className="icon">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9999 2H12.0333C16.7197 2.01842 20.5183 5.84197 20.4999 10.5223V10.6153C20.4366 13.96 18.4258 16.7356 16.7504 18.4746C16.2736 18.9718 15.7661 19.4489 15.2427 19.8944C14.9292 20.1619 14.4586 20.1251 14.1908 19.812C13.923 19.498 13.9607 19.028 14.2742 18.7605C14.7589 18.3475 15.2304 17.9037 15.6739 17.4416C17.1649 15.8946 18.9527 13.4487 19.0071 10.5881C19.022 6.65842 15.8916 3.50574 12.0272 3.48996H11.9999C8.14862 3.48996 5.00768 6.61194 4.99276 10.4609C5.05861 12.3622 5.70138 14.1696 6.85256 15.6859C8.19604 17.4749 10.2341 19.3691 12.1712 20.6302C12.5171 20.8547 12.6146 21.316 12.3889 21.6615C12.2467 21.8807 12.007 22 11.7628 22C11.6232 22 11.4827 21.9614 11.3563 21.879C9.28224 20.5293 7.0993 18.5 5.66011 16.5839C4.32453 14.8238 3.57727 12.7235 3.5 10.5127C3.51844 5.78585 7.32848 2 11.9999 2ZM10.2552 10.6141C10.2552 11.5727 11.0367 12.354 11.9973 12.354C12.9579 12.354 13.7394 11.5727 13.7394 10.6141C13.7394 9.65474 12.9579 8.87337 11.9973 8.87337C11.5855 8.87337 11.2509 8.54012 11.2509 8.12883C11.2509 7.71665 11.5855 7.38341 11.9973 7.38341C13.7807 7.38341 15.2322 8.83215 15.2322 10.6141C15.2322 12.3952 13.7807 13.844 11.9973 13.844C10.2139 13.844 8.76241 12.3952 8.76241 10.6141C8.76241 10.202 9.09696 9.86872 9.50879 9.86872C9.92061 9.86872 10.2552 10.202 10.2552 10.6141Z" fill="#130F26"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>FBridge SSF,Bharuch,Gujarat</h6>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="list-inline-item p-1">
                                                <div className="css_prefix-email d-flex">
                                                    <div className="icon">
                                                        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 10.3069C22 10.7006 21.6832 11.0198 21.2923 11.0198L21.2835 11.002C21.0958 11.002 20.9158 10.9269 20.7831 10.7932C20.6504 10.6595 20.5759 10.4782 20.5759 10.2891V8.84554C20.5519 6.41955 18.6055 4.45879 16.1973 4.43465H7.80274C5.39451 4.45879 3.44811 6.41955 3.42415 8.84554V15.1545C3.44811 17.5805 5.39451 19.5412 7.80274 19.5653H16.1973C18.6055 19.5412 20.5519 17.5805 20.5759 15.1545C20.6163 14.7891 20.923 14.5127 21.2879 14.5127C21.6529 14.5127 21.9595 14.7891 22 15.1545C21.9903 18.3788 19.398 20.9902 16.1973 21H7.80274C4.6 20.9951 2.00487 18.3808 2 15.1545V8.84554C2 5.61714 4.59798 3 7.80274 3H16.1973C19.398 3.00979 21.9903 5.6212 22 8.84554V10.3069ZM12.7386 11.8574L16.8518 8.54257H16.8607C17.167 8.29731 17.6119 8.34491 17.8602 8.6495C17.9785 8.798 18.0329 8.988 18.0113 9.17715C17.9897 9.36629 17.8939 9.53888 17.7452 9.65644L13.632 12.9802C12.5862 13.828 11.0954 13.828 10.0495 12.9802L5.92747 9.65644C5.6251 9.40629 5.57785 8.95805 5.82132 8.6495C5.93771 8.50163 6.10817 8.40703 6.29443 8.38693C6.4807 8.36682 6.66718 8.4229 6.81203 8.54257L10.9695 11.8574C11.4888 12.2679 12.2193 12.2679 12.7386 11.8574Z" fill="#130F26"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="cont">
                                                        <h6>farmbridge12@gmail.com</h6>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col text-end">
                                        <ul className="list-inline mb-0 p-1">
                                            <li className="list-inline-item">
                                                <nav className="navbar css_prefix-acount">
                                                    <div className="css_prefix-users-settings user-btn css_prefix-usermenu-dropdown dropdown">
                                                        <Link to="" className="css_prefix-button link">SIGN IN</Link>
                                                    </div>
                                                </nav>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>




            <div className="container-fluid nav-bg">
                <div className="row">
                    <div className="col-11 mx-auto">
                        <nav class="navbar navbar-expand-xl navbar-light p-0" id="site-navigation" aria-label="main-menu">
                            <div class="container-fluid">
                                <img src={Logo} alt="" width={200} height={60} />
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                        <li class="nav-item">
                                            <NavLink activeClassName="" exact className="nav-link active" aria-current="page" to="/">HOME</NavLink>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <NavLink className="nav-link dropdown-toggle" activeClassName="" to="" id="navbarDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                PAGES 
                                            </NavLink>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="main">
                                                <li className="sub-main"><Link className="dropdown-item" to="/about">ABOUT US</Link></li>
                                                <li className="sub-main"><Link className="dropdown-item" to="/ourwork">OUR WORK</Link></li>
                                                <li className="sub-main"><Link className="dropdown-item" to="/gallery">GALLERY</Link></li>
                                                </div>
                                            </ul>
                                        </li>
                                        <li class="nav-item dropdown">
                                            <NavLink className="nav-link dropdown-toggle" activeClassName="" to="/blogs" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                BLOGS
                                            </NavLink>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <div className="main">
                                                <li className="sub-main"><Link className="dropdown-item" to="/">BLOG SLID</Link></li>
                                                <li className="sub-main"><Link className="dropdown-item" to="/">BLOG VIDEO</Link></li>
                                                </div>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <NavLink className="nav-link active" activeClassName="" aria-current="page" to="/contact">CONTACT US</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;
import React from 'react'
import { Link } from 'react-router-dom';
import '../About.css'

const About = () => {
    return (
        <>
            <div className="container-fluid">
                <div className='abt1'>
                    <h1 className='cont'>About FarmBridge</h1>
                </div>
                <div className='row abt1_1 d-flex'>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <p className='abt1_cont'> Welcome to Farmbridge Social Support Foundation, a dedicated non-profit organization established in 2015 under the Companies Act of 2013. Our mission is aligned with the vision of the 12th Five Year Plan (2013-2018) set by the Government of India, focusing on achieving 'faster and more inclusive growth' through holistic development in rural areas. The key thrust areas identified by the government—Rural Livelihood, Rural Education, Rural Sanitation & Hygiene, Environment & Natural Resource Management. The focal points of our interventions is Rural Livelihood.
                            Recognizing the challenges in motivating and facilitating change, we believe that a multi-dimensional, ethical approach, coupled with local participation, is essential for effective transformation. "Bridging the knowledge gap" stands as our central strategy.
                        </p>
                    </div>
                    <div className='col-sm-12 col-md-12 col-xl-6'>
                        <p className='abt1_cont'>
                            By addressing the specific needs of various segments of the rural population, including Farmers, Women, Youth, Children, and Senior Citizens, we aim to create sustainable and positive impacts. Since our inception, Farmbridge has been dedicated to empowering Farmers, Women, and Youth across diverse regions in India. As a Section 8 organization, we operate with a non-profit motive, ensuring that every effort is directed towards the betterment of rural communities.
                            Our commitment extends beyond mere development initiatives. We strive to create lasting change by fostering a culture of knowledge-sharing, ethical practices, and community engagement. Through our various programs and projects, Farmbridge Social Support Foundation aims to contribute significantly to the overall well-being and progress of rural India.

                        </p>
                    </div>
                </div>
            </div>
            <div className='abt1_btn'>
                <div className='abt1_btn1'>
                    <Link to="/" className='abt1_btn2'>BACK</Link>
                </div>
            </div>
        
        </>
    )
}

export default About;